<template>
  <div class="card card-body shadow-sm infomations-list">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-basic-table
      ref="basic_table"
      :table-name="$t('news.list_title')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.KYY_NEWS_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-sm-12">{{ filters.title.label }}</label>
              <div class="col-sm-12 gr-icheck mt-0">
                <app-input :name="filters.title.name" input-style="normal"
                           v-model="filters.title.value"
                />
              </div>
            </div>
          </div>
          <!-- Search by category -->
          <div class="col-xl-6 col-lg-12 col-sm-12">
            <div class="form-group">
              <label class="col-sm-12">{{ filters.kyy_news_category_id.label }}</label>
              <div class="col-sm-12 gr-icheck mt-0">
                <app-select :name="filters.kyy_news_category_id.name"
                  input-style="normal"
                  v-model="filters.kyy_news_category_id.value"
                  :options-data="meta.categories"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common_vn.create") }}
        </button>
      </template>

      <template v-slot:body-cell-category="props">
        <td class="app-align-middle text-center app-p-none">
          {{ getLabelFromOptionArray(props.row.kyy_news_category_id, meta.categories) }}
        </td>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common_vn.detail") }}
          </button>
        </td>
      </template>

      <template v-slot:body-cell-image_path="props">
        <td class="app-align-middle" style="display: flex;justify-content: center;">
          <img :src="props.row.image.url" v-if="props.row.image.path" style="width: 100px; height: 100px">
        </td>
      </template>
    </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {getLabelFromOptionArray} from "@utils";

  export default {
    name: "NewsListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramGetList: {},
        filters: {
          title: {
            name: "title",
            condition: "like",
            label: this.$t("news.title")
          },
          kyy_news_category_id: {
            name: "kyy_news_category_id",
            condition: "equal",
            label: this.$t("news.category")
          },
        },
        columns: [
          {name: "image_path", label: this.$t("news.thumbnail"), textAlign: 'text-center'},
          {name: "category", label: this.$t("news.category"), textAlign: 'text-center'},
          {name: "title", label: this.$t("news.title"), sortable: true, textAlign: 'text-left'},
          {name: "edit", label: this.$t("common_vn.action"), textAlign: 'text-center'},
        ],
        meta: [],
        endPoint: '',
      };
    },
    methods: {
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_NEWS_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.KYY_NEWS_EDIT, params: {id: entry.id}})
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
    },
    mounted: async function () {
      this.$request.get(this.ENDPOINT.UI_HELPER_GET_NEWS_CATEGORIES).then(res => {
        let categories = _.cloneDeep(res.data.categories);
        categories = categories.map((category) => {
          return {
            id: category?.id,
            name: category?.name
          }
        })
        categories.unshift({
          id: 'all',
          name: '',
        });
        this.meta = {
          categories: categories,
        }
      })
    },
  }
</script>
